import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"

const VisionPage = () => {
    return (
        <Layout>
            <div className="vision-page">
                <section className="mv-wrap">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/vision/mission-txt-pc.svg"
                        alt="Create Valuable Moment"/>
                </section>
                <section className="contents-wrap">
                    <div className="mission-wrap">
                        <h2>MISSION</h2>
                        <h3 className="sub-title">人と“衣”との間に新たな価値を創造する</h3>
                        <p className="message">
                            “衣”は世界中の人にとって、なくてはならないもの。<br />
                            そして人と“衣”との間には未だ多くの課題があり、<br className="sp"/>
                            もっと素晴らしいものにできる可能性がある。<br/>
                            意志と発想をもって、人と"衣"の関係を変化させ、<br className="sp"/>
                            今までなかった価値を生み出すことで、<br />
                            世界を良い方向へ前進させる。
                        </p>
                    </div>
                    <div className="values-wrap">
                        <h2>VALUES</h2>
                        <div className="values-table">
                            <div className="row">
                                <div className="column">風を切って先頭を走ろう</div>
                                <div className="value">進むべき方向を見定め、スピード感と意志を持って新しい価値を生み出す。<br className="pc" />
                                    リスクを取る勇気と、走り切るパワーが必要だが、先頭を走るからこそ得られるものがある。
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">心躍らせるしごと</div>
                                <div className="value">美しいものを見たときの感動。心地よい操作感。安心感や感謝。<br className="pc" />
                                    そういった、人の心が動く瞬間を作り出し続けることに、心を踊らせていたい。
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">すべてのことに理由がある</div>
                                <div className="value">いかなる成功にもいかなる失敗にも理由がある。<br className="pc" />
                                    広く情報を集め、自分の目で観察し、深く考える。<br className="pc" />
                                    科学者の姿勢で臨み、本質的に価値のあるものを生み出す。
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">フェアプレーが一番強い</div>
                                <div className="value">環境や社会や人を傷つけない、嘘はつかない。<br className="pc" />
                                    ステークホルダー一人ひとり大事に、一つの判断が与える影響を理解し、<br className="pc" />
                                    良心を持って判断する。<br className="pc" />
                                    目先の成功にとらわれず、長く社会に貢献する。
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">初心を忘れない</div>
                                <div className="value">自身の未熟さを自覚するところから成長が始まる。<br className="pc" />
                                    決して驕らず、謙虚に、さらに進化できることを常に考える。<br className="pc" />
                                    人の心を動かし、世界を変えるためにチャレンジャーとしてスタートしたときの気持ちを持ち続ける。
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </Layout>
    )
}

export const Head = () => (
    <Seo
        title="ミッションステートメント"
        description="OKULAB（オクラボ）が目指すVISIONとMISSION。VISION「Create Valuable Moment」、MISSION「人と“衣”との間に新たな価値を創造する」"
    />
)
export default VisionPage


